@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://use.typekit.net/kdh6lxc.css");

@import "./common/breakpoints";
@import "./common/fonts";
@import "./common/vars";
@import "./common/mixin-common";
@import "./common/form";
@import "./common/input-field";
@import "./common/loading-spinner";
@import "./home/home";
@import "./login/login";
@import "./layout/layout";
@import "./layout/footer";
@import "./location/location";
@import "../styles/apartments/Filters-content/filters-content";
@import "./detail_product/detail_product";
@import "./apartments/apartments";
@import "./common/account";
@import "./list_apartment/index";
@import "./modal/index";
@import "./block_availability/index";
@import "./detail_product/frames";
@import "./admin_layout/admin_layout";
@import "./compare_image/index";
@import "./admin_documents/admin_documents";
@import "./compare_image/index";
@import "./show-pdf/index";
@import "./admin_my-details/admin_my-details";
@import "./form/inputField";
@import "./common/button";
@import "./admin_settings/admin_settings";
@import "./common/pointer";
@import "./admin_documents/popup-share-document";
@import "./common/select-field";
@import "./common/animation-apartment";
@import "./location/model_notice_zoom";
@import "./form/input-field-admin";
@import "./common/button-admin";
@import "./common/input-search-admin";
@import "./admin_favourites/admin_favourites";
@import "./common/modal-admin";
@import "./lifestyle/lifestyle";
@import "./common/select-admin";
@import "./common/textarea-admin";
@import "./media/media";
@import "./lifestyle_detail/lifestyle_detail";
@import "./team/team";
@import "./common/pdf-reader";
@import "./design//design";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: MrEavesXLModOT;
    -moz-user-select: none;
    -ms-user-select: none;

    ::-webkit-scrollbar {
        width: 9px;
        height: 9px;
        border-radius: 16px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
        border-radius: 16px;
    }
    ::-webkit-scrollbar-track:hover {
        background-color: #f4f4f4;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
        background-color: #a0a0a5;
        border-radius: 16px;
        z-index: 1000;
        /*     border:5px solid #a0a0a5 */
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
        /*     border:1px solid #a0a0a5 */
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
        display: none;
    }
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}
