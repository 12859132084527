.textarea-admin {
    label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #002a4e;
    }
    textarea {
        display: block;
        min-height: 90px;
        padding: 13px 16px;
        background: #ffffff;
        border: 2px solid #d7dbce;
        border-radius: 12px;
        width: 100%;
        outline: none;
        &::placeholder {
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
            color: #cea46f;
        }
    }
}
