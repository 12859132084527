.input-search-admin {
    height: 48px;
    background: #ffffff;
    border: 1.5px solid #d7dbce;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    input {
        padding: 0 40px 0 15px;
        position: absolute;
        inset: 0;
        border: none;
        outline: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #cea46f;
        &::placeholder {
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            color: #cea46f;
        }
    }
    img {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 15px;
    }
}
