.lifestyle {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #cea46f 52.31%, #002a4e 100%);
    display: flex;
    justify-content: center;

    @include tablet-max() {
        align-items: center;
    }

    &__wrapper {
        margin: 25px 76px 10px 76px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 36px;
        max-width: 70vw;

        @include desktop-extra-middle() {
            margin: 12px 76px;
            gap: 12px;
        }

        @include tablet-max() {
            height: 60%;
            margin: 60px 24px;
        }

        @include mobile-large() {
            display: none;
        }

        &--slide {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 80px;

            @include desktop-supper {
                gap: 180px;
            }

            @include desktop-super-middle-max() {
                gap: 100px;
            }

            @include desktop-extra-large-max() {
                gap: 60px;
            }

            @include desktop-extra-middle() {
                gap: 120px;
            }

            @include tablet-max() {
                gap: 40px;
            }
        }

        &--desc {
            border-radius: 20px 20px 0px 0px;
            background: rgba(207, 165, 112, 0.3);
            padding: 16px 32px;
            font-family: MrEavesXLModOT;
            font-style: normal;
            font-weight: 350;
            font-size: 24px;
            line-height: 27px;
            color: #ffffff;

            @include mobile-large() {
                width: 100vw;
                // font-size: ;
            }

            @include desktop-super-middle-max() {
                font-size: 20px;
            }

            @include desktop-extra-middle() {
                font-size: 12px;
                line-height: 15px;
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 16px;

                @include desktop-super-middle-max() {
                    gap: 8px;
                }
            }
        }

        .parallax-effect-glare-scale {
            display: flex;
            transform-style: preserve-3d;
            cursor: pointer;

            .glare-wrapper {
                border-top-left-radius: 30px !important;
                border-bottom-right-radius: 30px !important;
            }
        }
    }

    &__wrapper__mobile {
        display: none;

        @include mobile-large() {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 100px 48px 0px;
            width: 100%;
            height: 100%;
            gap: 32px;

            @include mobile-max-height() {
                padding: 10px 48px;
            }
        }

        .parallax-effect-glare-scale {
            display: flex;
            transform-style: preserve-3d;
            cursor: pointer;

            .glare-wrapper {
                border-top-left-radius: 30px !important;
                border-bottom-right-radius: 30px !important;
            }
        }

        .ant-carousel {
            width: 65vw;

            .slick-dots-bottom {
                bottom: -30px;
            }
        }
    }

    &__card {
        position: relative;
        width: 100%;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;

        @include mobile-large() {
            padding: 0px 10px;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            border-top-left-radius: 30px;
            border-bottom-right-radius: 30px;

            @media (min-width: 1200px) {
                height: calc(100vh - 22rem);
            }
        }

        &.parallaxHover__outter {
            width: 100%;
            height: 100%;
        }

        &--absolute {
            height: 40%;
            width: 100%;
            position: absolute;
            bottom: 0px;
            border-bottom-right-radius: 30px;
            background-image: linear-gradient(180deg,
                    rgba(0, 0, 0, 0) 0%,
                    #cea46f 100%);
            display: flex;
            align-items: flex-end;

            @include mobile-large() {
                width: calc(100% - 20px);
                left: 10px;
            }
        }
    }

    &__button {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0px 48px 41px 0px;
        gap: 29px;
        font-family: MrEavesXLModOT;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        color: #fff;

        @include desktop-supper-middle() {
            font-size: 20px;
            gap: 20px;
        }

        @include desktop-supper() {
            font-size: 24px;
            gap: 29px;
        }

        @include between-1025-to-1440() {
            padding: 0px 24px 24px 24px;
            font-size: 18px;
            gap: 20px;
        }

        @include screen-2560() {
            font-size: 30px;
        }

        @include tablet() {
            font-size: 18px;
            gap: 12px;
            padding: 18px 16px;
        }

        @include mobile-large() {
            padding: 18px 24px;
        }
    }
}