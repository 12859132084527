.account {
    width: 130px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #8a6e4b;
    border-radius: 3px;
    padding: 15px 8px;
    color: #8a6e4b;
    text-align: center;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    p {
        cursor: pointer;
        font-family: "Lausanne";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #8a6e4b;
    }
    p:not(:last-child) {
        border-bottom: 0.5px solid #8a6e4b;
        padding-bottom: 14px;
    }
    p:last-child {
        margin-bottom: 0px;
    }
}
.account-popup {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    position: absolute;
    top: 35px;
    right: 35px;
    border-radius: 50%;
    border: 1px solid #8a6e4b;
    color: #8a6e4b;
    width: 45px;
    height: 45px;
    cursor: pointer;
    background-color: #ffffff;
    text-align: center;
    z-index: 11;
    font-family: Neue Haas Grotesk Text Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;

    p {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0px;
    }
    img {
        width: 45px;
        height: 45px;
        border-radius: 50%;
    }
    @include mobile-large {
        width: 37px;
        height: 37px;
    }
}
