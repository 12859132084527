@mixin iphone667 {
    @media only screen and (max-width: 667px) {
        @content;
    }
}

@mixin ip7 {
    @media only screen and (max-width: 900px) {
        @content;
    }
}

.availability-title {
    position: fixed;
    top: calc(50% - 80px);
    transform: translateY(calc(-1px * calc(50% - 80px)));
    right: -160px;

    @include tablet-max {
        right: -150px;
    }
    @include mobile-large {
        right: -180px;
    }
    z-index: 10;
    transition: -0.5s;

    @include tablet-res-height {
        transform: translateY(calc(-1px * calc(50% - 70px)));
        z-index: 5;
    }
    button {
        background: #002a4e;
        border: 1px solid #004f39;
        transform: rotate(90deg);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 20px;
        font-size: 25px;
        color: #fff;
        border-top: 0;
        font-family: MrEavesXLModOT;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: center;

        span {
            @include mobile-large {
                visibility: hidden;
            }
        }
        @include desktop-extra-large-max {
            padding: 20px 40px;
        }
        @include desktop-extra-large {
            padding: 20px 20px 30px;
            font-size: 28px;
        }
        @include tablet-res-height {
            padding: 14px;
            font-size: 22px;
            margin-top: 20px;
            margin-right: 20px;
        }
        img {
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%) rotate(270deg);
            width: 40px;
            height: 40px;
            margin-right: 20px;
            cursor: pointer;

            border: 1px solid #f9dcab;
            padding: 6px;
            background: #f9dcab;
            border-radius: 50px;
        }
    }
}
.availability {
    position: absolute;
    top: 0;
    left: 100%;
    width: 94%;
    margin-top: 2vh;
    margin-left: 3%;
    z-index: 100;
    background: linear-gradient(180deg, #ffffff 0%, #f9dcab 76.04%);
    border: 1.5px solid #8a6e4b;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 50px 30px 50px 60px;
    transition: 0.5s;
    height: calc(92vh - #{$height-menu-responsive});
    @include desktop-extra-large {
        height: calc(92vh - #{$height-menu});
    }
    @include mobile-large {
        width: 90%;
        padding: 10px 5px 10px 20px;
    }
    &.active {
        left: 0;
    }
    .icon-close {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50px);
        right: -21px;

        border: 1px solid #f9dcab;
        padding: 12px;
        background: #f9dcab;
        border-radius: 50px;
    }
    &__title {
        font-family: MrEavesXLModOT;
        font-size: 42px;
        line-height: 44px;
        color: #004f39;
        margin-bottom: 40px;
        @include mobile-large {
            font-size: 35px;
            margin-bottom: 0;
        }
    }
    &__line {
        position: relative;
        margin-bottom: 30px;
        margin-top: 20px;
        &::after {
            content: "";
            width: calc(100% - 80px);
            height: 1px;
            background: #8a6e4b;
            display: block;
            position: absolute;
            top: 10px;
            left: 80px;
        }
        span {
            position: relative;
            z-index: 1;
            padding-right: 20px;
        }
    }
    &__scroll {
        overflow: auto;
        height: calc(100% - 115px);
        padding-right: 30px;
        @include scroll-custom;
        @include mobile-large {
            height: calc(100% - 140px);
        }
    }
}
.availability-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    &__item {
        cursor: pointer;
        width: calc(12.5% - 40px);
        height: 200px;
        background-color: #d7dbce;
        height: 80px;
        margin-left: 40px;
        margin-bottom: 30px;

        &--disabled {
            cursor: no-drop;
            opacity: 0.5;
        }

        @include tablet-max {
            width: calc(12.5% - 25px);
            margin-left: 25px;
        }
        @include tablet-mini {
            width: calc(16.6% - 25px);
            margin-left: 25px;
        }
        @media only screen and (max-width: 992px) {
            width: calc(25% - 25px);
            margin-left: 25px;
        }

        @include ip7 {
            width: calc(16.6% - 25px);
            margin-left: 25px;
        }

        @include iphone667 {
            width: calc(20% - 25px);
            margin-left: 25px;
        }

        @include mobile-large {
            width: calc(33.3% - 15px);
            margin-left: 15px;
        }

        p {
            margin-bottom: 0;
            width: 100%;
            height: 30px;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            color: #243670;
            font-family: Proxima Nova;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        span {
            text-align: center;
            width: 100%;
            height: 20px;
            font-family: Proxima Nova;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #243670;
            background: #ef684d;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        }

        &--price {
            font-weight: 700 !important;
        }
    }
    &__AVAILABLE {
        background: rgb(179, 228, 250) !important;
    }
    &__SOLD {
        background: rgb(240, 78, 38) !important;
    }
    &__RESERVED {
        background: rgb(245, 242, 162) !important;
    }
    &__WITHHELD {
        background: rgb(148, 148, 148) !important;
    }
}
.d-flex {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    @media (max-width: 767px) {
        font-size: 10px;
        justify-content: start;
    }
}

.d-md-flex {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 767px) {
    .d-block {
        display: block;
    }
}
.card {
    padding: 5px 15px;
    font-size: 20px;
    &:not(:first-child) {
        margin-left: 0.5rem;
    }

    .item {
        margin-left: 5px;
        padding: 5px 15px;
    }

    &__AVAILABLEtrue {
        background: rgb(179, 228, 250) !important;
    }
    &__SOLDtrue {
        background: rgb(240, 78, 38) !important;
    }
    &__RESERVEDtrue {
        background: rgb(245, 242, 162) !important;
    }
    &__WITHHELDtrue {
        background: rgb(148, 148, 148) !important;
    }

    @media (max-width: 767px) {
        display: block;
        padding: 0px 0px;
        margin-top: 0.5rem;
        font-size: 8px;
        &:not(:first-child) {
            margin-left: 0.5rem;
        }

        .item {
            margin-left: 5px;
            padding: 5px 15px;
        }
    }
    @media (min-width: 768px) {
    }
    @media (min-width: 992px) {
    }
    @media (min-width: 1024px) {
    }
    @media (min-width: 1440px) {
    }
}
