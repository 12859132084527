.btn-admin-compare-share {
    height: 48px;
    background: #fceed5;
    border: 2px solid #cea46f;
    border-radius: 200px;
    padding: 0 24px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #002a4e;
    cursor: pointer;
}
.block-search {
    margin-bottom: 20px;
    &__left {
        display: flex;
        justify-content: end;
        @include tablet-max {
            justify-content: start;
        }
    }
    .ml-10 {
        margin-left: 10px;
    }
}
.list-favoutites {
    margin-bottom: 40px;
    @include tablet-max {
        padding: 0 10px;
    }
}
.item-apartment {
    border: 1px solid #cea46f;
    border-radius: 12px;
    overflow: hidden;
    height: 100%;
    &__top {
        height: 160px;
        position: relative;
        .img-item {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .checkbox-admin {
            position: absolute;
            top: 15px;
            left: 15px;
        }
        .icon-share {
            cursor: pointer;
            position: absolute;
            top: 18px;
            right: 95px;
            width: 24px;
            height: 24px;
        }
        .icon-share-fav {
            cursor: pointer;
            position: absolute;
            top: 18px;
            right: 55px;
            width: 24px;
            height: 24px;
        }
        .icon-download {
            cursor: pointer;
            position: absolute;
            top: 18px;
            right: 55px;
            width: 24px;
            height: 24px;
        }
        .icon-download-fav {
            cursor: pointer;
            position: absolute;
            top: 18px;
            right: 15px;
            width: 24px;
            height: 24px;
        }
        .icon-delete {
            cursor: pointer;
            position: absolute;
            top: 18px;
            right: 15px;
            width: 24px;
            height: 24px;
        }
    }
    &__bottom {
        padding: 15px;
    }
    &__info {
        padding-bottom: 12px;
        h2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: PlayfairDisplay;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #002a4e;
            line-height: 20px;
            margin-bottom: 8px;
            svg {
                cursor: pointer;
                path {
                }
            }
        }
        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            color: #8a6e4b;
        }
    }
    &__detail {
        border-top: 1px solid #d7dbce;
        padding-top: 12px;
        padding-bottom: 14px;
        &:last-child {
            padding-bottom: 0;
        }
        span {
            font-family: Roboto;
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            color: #002a4e;
        }
        p {
            display: flex;
            align-items: center;
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #8a6e4b;
            margin-bottom: 0px;
            line-height: 10px;
            img {
                margin-right: 10px;
            }
        }
    }
    &__bottom-document {
        padding: 15px;
    }
    &__bottom-document-title {
        font-family: PlayfairDisplay;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 132%;
        letter-spacing: 0.04em;
        color: #002a4e;
        word-break: break-all;
        margin-bottom: 0;
    }
    &__bottom-document-desc {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 128%;
        color: #8a6e4b;
    }
    &__bottom-document-time {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: rgba(0, 42, 78, 0.5);
        span {
            font-weight: 700;
            color: #002a4e;
        }
    }
    &.selected {
        .item-apartment__bottom {
            background: #004533;
        }
        .item-apartment__info h2 {
            color: #fff;
        }
        .item-apartment__info p {
            color: #f9dcab;
        }
        .item-apartment__detail p {
            color: #f9dcab;
        }
        .item-apartment__detail span {
            color: #fff;
        }
        .item-apartment__bottom-document {
            background: #004533;
            height: 100%;
        }
        .item-apartment__bottom-document-title {
            color: #fff;
        }
        .item-apartment__bottom-document-desc {
            color: #f9dcab;
        }
        .item-apartment__bottom-document-time {
            color: #fff;
            span {
                color: #fff;
            }
        }
    }
}
.item-apartment-list {
    padding: 12px 5px;
    border: 1px solid #cea46f;
    border-radius: 12px;
    &__left {
        display: flex;
        align-items: center;
        .checkbox-admin {
            margin-right: 20px;
        }
        img {
            width: 64px;
            height: 64px;
            border-radius: 8px;
            margin-right: 20px;
            object-fit: cover;
        }
        h2 {
            font-family: PlayfairDisplay;
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 132%;
            letter-spacing: 0.04em;
            color: #002a4e;
            margin-bottom: 0px;
        }
        p {
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 128%;
            color: #8a6e4b;
        }
    }
    &__right {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: space-between;
        P {
            font-family: Roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: rgba(0, 42, 78, 0.5);
        }
        span {
            font-weight: 700;
            color: #002a4e;
        }
        .content {
            display: flex;
            align-items: center;
            img {
                cursor: pointer;
                margin-left: 30px;
                path {
                    fill: #002a4e;
                }
            }
            a {
                display: flex;
            }
        }
    }
    &.selected {
        background: #004533;
        border: 1px solid #b3e4fa;
        .item-apartment-list__left {
            h2 {
                color: #fff;
            }
            p {
                color: #f9dcab;
            }
        }
        .item-apartment-list__right {
            p {
                color: #fff;
            }
            span {
                color: #fff;
            }
        }
    }
}
.checkbox-admin {
    .ant-checkbox-input {
        &:focus {
            border: 1px solid #cea46f;
        }
    }
    .ant-checkbox-inner {
        width: 24px;
        height: 24px;
        border: 1px solid #cea46f;
        border-radius: 4px;
        background: rgba(255, 255, 255, 0.5);
        &::after {
            left: 30%;
        }
    }
    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background: #8a6e4b;
        }
    }
}
