.account-layout {
    position: relative;
    &__logo {
        width: 100%;
        text-align: center;
        position: relative;
        margin-bottom: 40px;
        svg {
            display: block;
            margin: 0 auto;
        }
        &:after {
            content: "";
            width: 100%;
            height: 0.5px;
            background-color: #8a6e4b;
            position: absolute;
            bottom: -20px;
            left: 0;
        }
    }
    .ant-layout-sider {
        width: 262px !important;
        max-width: 262px !important;
        flex: 0 0 262px !important;
    }
    .ant-menu-item {
        margin: 12px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:last-child {
            height: auto;
            border-top: 1px solid #8a6e4b;
            margin-top: 20px;
            padding-top: 20px;
        }
        a {
            color: #8a6e4b;
            font-family: Roboto;
            font-size: 24px;
            font-weight: 400px;
        }
        &:active {
            background-color: transparent !important;
        }
    }
    .ant-menu-item-icon {
        @include desktop-extra {
            min-width: 20px;
        }
        @include mobile-large {
            min-width: 14px;
        }
    }
    .ant-menu-item {
        @include tablet-max {
            padding: 0 34px;
            &:last-child {
                border-top: none;
                margin-top: unset;
                padding-top: unset;
            }
        }
        @include tablet-mini {
            padding: 0 25px;
        }
        @include mobile-large {
            padding: 0 9px;
        }
    }
    .ant-menu-item-selected {
        background-color: transparent !important;
        img {
            filter: brightness(0) invert(1);
        }
        a {
            color: #ffffff;
        }
    }
    &__header {
        &__wrap {
            width: 100%;
            padding: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: MrEavesXLModOT;
            h4 {
                color: #8a6e4b;
                font-size: 24px;
                font-family: MrEavesXLModOT;
                margin-bottom: 0;
                font-weight: 700;
                span {
                    font-size: 40px;
                    font-weight: bold;
                    color: #8a6e4b;
                    font-family: MrEavesXLModOT;
                }
                @include mobile-large {
                    font-size: 20px;
                }
            }
            a {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .account-layout__header__right {
                cursor: pointer;
                background: #ffffff;
                border: 2px solid #d7dbce;
                border-radius: 200px;
                display: flex;
                align-items: center;
                padding: 0px 20px;
                height: 48px;
                img {
                    margin-right: 12px;
                    margin-bottom: 3px;
                }
                a {
                    font-weight: 700;
                    font-size: 18px;
                    font-family: Roboto;
                    color: #002a4e;
                }
            }
            @include mobile-large {
                padding: 30px 10px;
            }
            @include mobile-large {
                flex-direction: column;
            }
        }
    }
    p {
        margin-bottom: 0;
    }
    &__menu {
        background-color: #002a4e !important;
        padding: 30px 20px;
        transition: 0.3s;
        @include tablet-max {
            position: absolute;
            inset: 0;
            left: -100%;
            z-index: 1;
            max-width: 100%;
            &.show {
                left: 0%;
            }
        }
        .show-menu {
            cursor: pointer;
            position: absolute;
            top: 0;
            right: -40px;
            width: 40px;
            height: 40px;
            padding: 10px;
            background: #fff;
            display: none;
            @include tablet-max {
                display: block;
            }
        }
        &.show {
            .show-menu {
                right: 0;
            }
        }
        p {
            font-family: MrEavesXLModOT;
            font-size: 24px;
            font-weight: 800;
        }
    }
    &__content {
        background: linear-gradient(
            180deg,
            #fbe3bc 0%,
            #ffffff 100%
        ) !important;
        @include tablet-max {
            flex: auto;
            max-width: 100%;
        }
    }
    .ant-layout-content {
        background-color: transparent !important;
    }
    .ant-menu {
        background-color: transparent;
    }
}
