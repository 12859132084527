.media {
    max-width: 100%;
    margin: auto;
    padding: 150px 100px 80px;

    &-item {
        height: 100%;
        text-align: center;

        img {
            width: auto;
            height: 100%;
            max-width: 100%;
            object-fit: contain;
            margin: auto;
        }

        &--video {
            position: relative;
            iframe,
            object,
            embed {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }

            &-frame {
                height: 100%;
                & > div {
                    padding: 0 !important;
                    height: 100%;

                    #player {
                        height: 100% !important;
                    }
                }
            }
        }
    }

    &-slider {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;

        &-wrapper {
            margin-top: 5px;
            .slick-slide {
                height: 580px;
                z-index: -1;

                &.slick-active {
                    z-index: 1;
                }

                & > div {
                    height: 100%;
                }
            }
            .slick-prev:before,
            .slick-next:before {
                display: none !important;
            }
        }

        &-navigator {
            position: absolute;
            display: inline-block;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: fit-content;
            cursor: pointer;

            &:hover {
                opacity: 0.75;
            }

            &-prev {
                top: -20px;
            }

            &-next {
                bottom: -40px;
            }
        }

        &-thumbnail {
            flex: 0 0 150px;
            max-width: 150px;
        }

        &-container {
            flex-grow: 1;
            flex: 0 0 calc(100% - 150px);
            max-width: calc(100% - 150px);
            padding-left: 50px;
        }

        &-thumb {
            &-wrapper {
                position: relative;
            }

            &-item {
                cursor: pointer;

                img {
                    width: 100%;
                    height: 90px;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .slick-list {
                height: 580px !important;
            }

            .slick-slide {
                border: none !important;
                padding: 5px 0;
                height: 110px !important;

                .media-slider-thumb-item {
                    position: relative;

                    &.active {
                        &::before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background-color: transparent;
                            z-index: 2;
                            border: 3px solid #002a4e;
                        }
                    }
                }
            }
        }
    }

    /** ----------- Responsive ---------*/
    @media (max-height: 800px) {
        &-slider {
            &-thumb {
                .slick-list {
                    height: 440px !important;
                }
            }

            &-wrapper {
                .slick-slide {
                    height: 420px;
                }
            }
        }
    }

    @media (max-height: 720px) {
        &-slider {
            &-thumb {
                .slick-list {
                    height: 330px !important;
                }
            }

            &-wrapper {
                .slick-slide {
                    height: 320px;
                }
            }
        }
    }

    @media (max-height: 640px) {
        padding: 100px 100px 80px;
    }

    @media (max-width: 1024px) {
        padding: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        &-slider {
            &-thumbnail {
                display: none;
            }

            &-container {
                flex: 0 0 100%;
                max-width: 100%;
                padding-left: 0;

                .slick-arrow {
                    &::before {
                        display: none;
                    }

                    &.slick-next {
                        right: -30px;
                        svg {
                            transform: rotate(90deg);
                        }
                    }

                    &.slick-prev {
                        left: -30px;
                        svg {
                            transform: rotate(90deg);
                        }
                    }

                    &.slick-disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
            }
        }

        &-item {
            &--video {
                position: relative;
                padding: 56.25% 0 0 0;
            }
        }
    }
}

.h-100 {
    height: 100%;
}
