.select-admin {
    label {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #002a4e;
    }
    .ant-select-selector {
        min-height: 48px;
        background: #ffffff;
        border: 2px solid #d7dbce !important;
        border-radius: 12px !important;
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #cea46fed;
        outline: none;
    }
    .ant-select-selection-placeholder {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #cea46f;
    }
    .ant-select-selection-item {
        background: #fceed5;
        border-radius: 8px;
    }
    .ant-select-selection-item-content {
        font-family: Roboto;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 150%;
        color: #000000;
    }
}
