.block-pdf {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    background-color: #2f3728;
    text-align: center;
    &__title {
        height: 75px;
        font-size: 20px;
        font-family: Lausanne;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        padding: 4px 10px;
        span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }
        @include screen-ipad-pro {
            flex-direction: column;
        }
    }
    &__btn-back {
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 40px;
        background: transparent;
        border: 1px solid #fff;
        border-radius: 15px;
        padding: 3px 15px;
        font-size: 16px;
        display: flex;
        @include screen-ipad-pro {
            position: unset;
            top: unset;
            transform: unset;
            left: unset;
        }
        svg {
            margin-right: 10px;
        }
    }
    iframe {
        width: 100%;
        height: calc(100vh - 190px);
        border: none;
    }
    object {
        width: 100%;
        height: calc(100vh - 190px);
        border: none;
    }
    &__btn-see-pdf {
        background: #243670;
        color: #fff;
        display: inline-block;
        margin-top: 100px;
        padding: 10px 30px;
        border-radius: 8px;
        font-size: 20px;
    }
}
