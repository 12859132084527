.design {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #cea46f 52.31%, #002a4e 100%);
    position: relative;

    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 50px 100px 0 100px;

        @include tablet-max {
            padding: 0px 100px;
        }

        @include mobile-large {
            padding: 40px 0px;
        }

        @include mobile-max-height {
            overflow: hidden;
            overflow-y: auto;
        }

        &--btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0px 20px;

            svg {
                cursor: pointer;
            }

            @include mobile-large {
                bottom: -20px;
                top: unset;
                justify-content: center;
                gap: 16px;
                padding: 0px;
            }
        }

        .ant-carousel {
            width: 100%;

            .slick-track {
                display: flex;
                align-items: center;
            }
        }
    }

    &__carousel {
        width: 100%;
        height: 100%;
        display: flex !important;

        &--first {
            display: flex !important;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 48px;

            @include desktop-extra-middle {
                gap: 24px;
            }

            @include tablet-max {
                gap: 28px;
            }

            @include mobile-large {
                gap: 24px;
            }

            img {
                width: 40%;

                @include screen-2560 {
                    width: 60%;
                }

                @include desktop-extra-middle {
                    width: 30%;
                }

                @include tablet-max {
                    width: 100%;
                }

                @include mobile-large {
                    width: 70%;
                }
            }
        }

        &--second {
            display: flex !important;
            gap: 5%;
            justify-content: center;

            @include tablet-max {
                flex-direction: column;
                gap: 18px;
            }
        }
    }

    &__first {
        &--info {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 24px;

            @include desktop-extra-middle {
                gap: 12px;
            }

            @include tablet-max {
                gap: 16px;
            }

            @include mobile-large {
                gap: 12px;
            }
        }

        &--title {
            font-size: 36px;
            line-height: 40px;
            font-family: MrEavesXLModOT;
            font-style: normal;
            font-weight: 400;
            color: #ffffff;

            @include screen-2560 {
                font-size: 48px;
                line-height: 52px;
            }

            @include desktop-super-middle-max {
                font-size: 32px;
                line-height: 36px;
            }

            @include desktop-extra-middle {
                font-size: 28px;
                line-height: 32px;
            }

            @include tablet-max {
                font-size: 32px;
                line-height: 36px;
            }

            @include mobile-large {
                font-size: 24px;
                line-height: 28px;
            }
        }

        &--content {
            font-size: 24px;
            line-height: 28px;
            font-family: MrEavesXLModOT;
            font-style: normal;
            font-weight: 400;
            color: #ffffff;

            @include screen-2560 {
                font-size: 26px;
                line-height: 30px;
            }

            @include desktop-super-middle-max {
                font-size: 20px;
                line-height: 24px;
            }

            @include desktop-extra-middle {
                font-size: 16px;
                line-height: 20px;
            }

            @include tablet-max {
                font-size: 20px;
                line-height: 24px;
            }

            @include mobile-large {
                font-size: 13px;
                line-height: 17px;
            }
        }
    }

    &__second {
        &--image {
            display: flex;
            flex-basis: 50%;
            img {
                width: 100%;
                object-fit: contain;
            }
        }

        &--content {
            display: flex;
            flex-basis: 50%;
            flex-direction: column;
            gap: 70px;

            @include screen-2560 {
                gap: 100px;
            }

            @include desktop-super-middle-max {
                gap: 50px;
            }

            @include tablet-max {
                gap: 20px;
            }
        }

        &--title {
            font-family: MrEavesXLModOT;
            font-style: normal;
            font-weight: 400;
            font-size: 48px;
            line-height: 52px;
            align-items: center;
            color: #ffffff;

            @include screen-2560 {
                font-size: 70px;
                line-height: 74px;
            }

            @include desktop-super-middle-max {
                font-size: 42px;
                line-height: 46px;
            }

            @include desktop-extra-large-max {
                font-size: 38px;
                line-height: 42px;
            }

            @include tablet-max {
                font-size: 30px;
                line-height: 34px;
            }

            @include mobile-large {
                font-size: 24px;
                line-height: 26px;
            }
        }

        &--info {
            display: grid;
            grid-template-columns: auto auto;
        }

        &--long-text {
            font-family: MrEavesXLModOT;
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: center;
            color: #ffffff;
            display: flex;
            flex-direction: column;

            @include screen-2560 {
                font-size: 42px;
                line-height: 46px;
            }

            @include desktop-super-middle-max {
                font-size: 20px;
                line-height: 24px;
            }

            @include desktop-extra-large-max {
                font-size: 16px;
                line-height: 20px;
            }

            @include desktop-extra-middle {
                font-size: 14px;
                line-height: 17px;
            }

            @include tablet-max {
                font-size: 14px;
                line-height: 18px;
            }

            @include mobile-large {
                font-size: 13px;
                line-height: 15px;
            }
        }

        &--item {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 16px;

            @include mobile-large {
                gap: 6px;
            }

            svg {
                @include mobile-large {
                    width: 24px;
                    height: 24px;
                }
            }

            p {
                font-family: MrEavesXLModOT;
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                display: flex;
                align-items: center;
                color: #ffffff;
                padding-right: 50px;

                @include screen-2560 {
                    font-size: 42px;
                    line-height: 46px;
                }

                @include desktop-super-middle-max {
                    font-size: 20px;
                    line-height: 24px;
                }

                @include desktop-extra-large-max {
                    font-size: 16px;
                    line-height: 20px;
                }

                @include desktop-extra-middle {
                    font-size: 14px;
                    line-height: 17px;
                }

                @include tablet-max {
                    font-size: 14px;
                    line-height: 18px;
                }

                @include mobile-large {
                    font-size: 13px;
                    line-height: 15px;
                    padding-right: 0px;
                }
            }
        }
    }
}
