.pointer {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    display: block;
    -webkit-animation: linear infinite;
    -webkit-animation-name: run;
    -webkit-animation-duration: 4s;
    p {
        font-family: Lausanne;
        font-size: 24px;
        color: #ffffff;
    }
    @include desktop-extra-large {
        display: none;
    }
}
.filter__img {
    filter: brightness(70%);
    @include desktop-extra-large {
        filter: none;
    }
}

@keyframes run {
    0% {
        left: 35%;
    }
    50% {
        left: 65%;
    }
    100% {
        left: 35%;
    }
}
