.form-main {
    .input-main {
        width: 100%;
        margin: 0 auto 4px;
    }
    &__btn-submit {
        border: none;
        width: 60%;
        margin: 45px auto 15px;
        padding: 10px 30px;
        font-family: Lausanne;
        font-size: 18px;
        cursor: pointer;
        background-color: #002a4e;
        border-radius: 10px;
        color: #ffffff;
        text-align: center;
        transition: 0.5s;
        &:hover {
            background-color: #093c69;
        }
        @media screen and (max-width: 1440px) {
            margin: 15px auto 15px;
        }

        @include mobile {
            margin: 5px auto 15px;
            width: auto;
            padding: 5px 30px;
        }
        &.disabled {
            background-color: #746667;
        }
    }
    &__forgot-password {
        font-family: Lausanne;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        cursor: pointer;
        color: #093c69;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
            color: #a71f2a;
        }
        
        .list-active {
            &::after {
                display: none;
            }
        }
    }
    span {
        display: block;
        font-size: 16px;
        color: red;
        margin-top: 20px;
        font-family: Lausanne;
        &.success {
            color: #3bd253;
        }
    }

    &__register {
        font-size: 17px;
        line-height: 20px;
        font-weight: 500;

        span {
            display: inline;
            font-size: unset;
            color: unset;
            margin-top: 0px;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
