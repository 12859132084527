$desktop-width: 1440px;
$tablet-width: 1280px;
$tablet_s-width: 1024px;

@mixin desktop {
    @media only screen and (max-width: $desktop-width) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (max-width: $tablet-width) {
        @content;
    }
}

@mixin tablet_S {
    @media only screen and (max-width: $tablet_s-width) {
        @content;
    }
}

.section-footer {
    padding: 0 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    transition: 0.5s;
    // position: fixed;
    z-index: 99;
    background-color: #002a4e;
    height: 100px;
    min-height: 100px;

    @include screen-ipad-pro {
        display: none;
    }

    @include desktop-extra-large {
        min-height: 120px;
    }

    &__middle {
        position: absolute;
        font-size: 40px;
        color: #f04e26;
        left: 50%;
        top: 77px;
        transform: translate(-50%, -50%);
        @include desktop-extra-middle {
            top: 54px;
        }
    }
    &__main-logo {
        // width: 165px;
        // height: 162px;
        // position: absolute;
        // left: 50%;
        // top: -70px;
        // transform: translateX(-50%);
        // padding: 10px;
        background-color: #002a4e;
        // border-top-left-radius: 30px;
        // border-top-right-radius: 30px;
    }
    &__logo-link {
        display: inline-block;
        flex: 0 0 15%;
        min-width: 120px;
        text-align: center;
    }
    &__logo {
        width: auto;
        height: 100px;
        padding: 10px;

        @include desktop-extra-large {
            min-height: 120px;
        }

        @include tablet {
            height: 100px;
        }
    }
    &__nav-left {
        li {
            text-align: right;
        }
    }
    &__nav-right {
        li {
            text-align: left;
        }
    }
    &__nav-left,
    &__nav-right {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        flex: 0 0 42%;

        li {
            // flex: 0 0 33.333%;
            // max-width: 33.333%;
            // text-align: center;
            flex-grow: 1;
            margin: auto 0;
            font-weight: 400;
            font-size: 34px;
            line-height: 18px;
            text-transform: uppercase;
            font-family: MrEavesXLModOT;

            @include desktop-extra-middle() {
                font-size: 25px;
            }

            @include tablet_S {
                font-size: 20px;
            }

            a {
                color: #8a6e4b;
                font-family: MrEavesXLModOT;
            }
        }
    }
    ul {
        margin-bottom: 0;
    }
}
.list-active {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #8a6e4b;

        @include mobile {
            height: 1px;
            bottom: -2px;
            left: -5%;
            width: 110%;
        }
    }
    &:hover::after {
        background-color: #8a6e4b;
    }
}
.mobile-footer {
    height: 52px;
    position: relative;
    background: #002a4e;
    z-index: 1000;
    transition: all 0.3s linear;

    &__control {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: relative;

        .control__icon {
            cursor: pointer;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 35px;
        }

        .control__logo {
            height: 40px;
        }
    }

    &__list {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 100%;
        left: 0;
        background: #002a4e;

        a {
            font-family: "Cucina";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            text-transform: capitalize;
            color: #8a6e4b;
        }
    }

    &__item {
        width: 100%;
        padding: 12px;
    }

    display: none;
    @include screen-ipad-pro {
        display: block;
    }
}

.mobile-footer--showList {
    transform: translateY(-290px);
}
