.container {
    height: 100vh;
    overflow-y: hidden;
    @include screen-ipad-pro {
        height: calc(var(--vh, 1vh) * 100);
    }
    .content {
        height: calc(100vh - #{$height-menu-responsive});
        z-index: 1;
        @include desktop-extra-large {
            height: calc(100vh - #{$height-menu});
        }
        @include screen-ipad-pro {
            height: calc(calc(var(--vh, 1vh) * 100) - #{$height-mobile-menu});
        }
    }
    .footer {
        // height: $height-menu-responsive;
        // height: 80px;
        position: fixed;
        width: 100%;
        left: 0;
        z-index: 9999;
        background: #002a4e;
        bottom: 0;
        @include desktop-extra-large {
            // height: $height-menu;
        }
    }

    .button-blue {
        color: #243670;
        background-color: #f5f2a2;
        border-radius: 10px;
        text-transform: uppercase;
        margin: 10px 5px;
        font-size: 18px;
        font-weight: 400;
        min-width: 120px;
        text-align: center;
        flex: 0 0 45%;
        line-height: 20px;
        max-width: 150px;
        padding: 10px 0px;
        &:hover {
            opacity: 0.8;
        }
        &:disabled {
            cursor: unset;
        }
    }

    .flex-wrap {
        flex-wrap: wrap !important;
    }

    .flex-inline {
        display: inline-flex !important;
    }
}

.bg-gadient-1 {
    background: linear-gradient(180deg, #cea46f 52.31%, #002a4e 100%);
}

.mw-100 {
    max-width: 100%;
}
