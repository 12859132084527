.pdf-reader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    overflow-y: auto;

    @include mobile-large {
        overflow: hidden;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px 0;
        padding: 10px;

        &--document {
            margin: 8px 0px;
            display: flex;
            justify-content: center;

            .react-pdf__Document {
                display: flex;
                align-items: center;
                width: 60%;
                position: relative;

                &:hover {
                    .page-controls {
                        opacity: 1;
                    }
                }

                .page-controls {
                    opacity: 0;
                    position: absolute;
                    bottom: 7%;
                    left: 50%;
                    transform: translateX(-50%);
                    background: #002a4e;
                    color: #fff;
                    -webkit-box-shadow: 10px 10px 20px 0px #333;
                    box-shadow: 10px 10px 20px 0px #333;
                    border-radius: 4px;
                    transition: opacity ease-in-out 0.3s;

                    span {
                        margin: 0 12px;
                    }

                    button {
                        width: 44px;
                        height: 44px;
                        background: #002a4e;
                        border: 0;
                        border-radius: 4px;
                        font-size: 12px;

                        &:hover {
                            cursor: pointer;
                            background: #025ead;
                        }

                        &:disabled {
                            background: #025ead;
                            cursor: unset;
                        }

                        &:first-child {
                            border-top-right-radius: 0;
                            border-bottom-right-radius: 0;
                        }

                        &:last-child {
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                        }
                    }
                }
            }

            .react-pdf__Page {
                max-width: calc(100% - 2em);
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
                margin: 1em;
            }

            .react-pdf__Page canvas {
                max-width: 100%;
                height: auto !important;
            }

            .react-pdf__message {
                padding: 20px;
                color: white;
            }
        }
    }
}

@keyframes opacity {
    0% {
        opacity: 0%;
    }
    25% {
        opacity: 25%;
    }
    50% {
        opacity: 50%;
    }
    75% {
        opacity: 75%;
    }
    100% {
        opacity: 100%;
    }
}
