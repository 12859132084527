.home {
    width: 100%;
    height: calc(100vh - #{$height-menu-responsive});
    background-position: center;
    background-repeat: no-repeat;
    // background-size: 100% 100%;
    background-size: cover;

    @include tablet-max {
        height: 100%;
    }

    background-color: #002a4e;
    position: relative;
    overflow: hidden;
    @include desktop-extra-large {
        height: calc(100vh - #{$height-menu});
    }
    iframe {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 100%;
        height: 100%;

        @include tablet-large {
            min-height: 100vh;
        }

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
