.modal-main {
    width: 50% !important;
    padding-bottom: $height-menu-responsive !important;
    @include desktop-extra-large {
        padding-bottom: $height-menu !important;
    }
    @include tablet-max {
        width: 70% !important;
    }
    @include mobile-large {
        width: 90% !important;
    }
    .ant-modal-content {
        background: #fff;
        border: 1.5px solid #002a4e;
        border-radius: 50px;
        padding: 30px 50px;
        overflow: auto;
        max-height: calc(100vh - calc(#{$height-menu-responsive} + 20px));
        @include desktop-extra-large {
            max-height: calc(100vh - calc(#{$height-menu} + 20px));
        }
        @include tablet-max {
            padding: 0;
        }
        &::-webkit-scrollbar {
            width: 0px;
        }
    }
    .ant-modal-close {
        right: 24px !important;
        top: 12px !important;
    }
    .ant-modal-body {
        padding: 0;
    }
}
.modal-enquire {
    padding-bottom: 162px !important;
    @include desktop-extra-large {
        padding-bottom: 162px !important;
    }
    @include tablet-max {
        width: 90% !important;
    }

    .ant-modal-content {
        overflow: auto;
        border-radius: 50px;
        border: 1px solid #002a4e;
        padding: 64px 45px 64px 64px;
        max-height: calc(100vh - calc(162px + 20px));

        &::-webkit-scrollbar {
            width: 0px;
        }

        @include desktop-extra-large {
            max-height: calc(100vh - calc(162px + 20px));
        }
        @include mobile-large {
            padding: 15px 5px;
        }
    }
    &__content {
        padding: 30px 60px;
        @include mobile-large {
            padding: 15px 10px;
        }
    }
    &__title {
        font-family: MrEavesXLModOT;
        font-size: 18px;
        line-height: 32px;
        color: #002a4e;
        text-transform: uppercase;
        text-align: center;
    }
    &__title-main {
        font-family: MrEavesXLModOT;
        font-size: 56px;
        line-height: 44px;
        text-transform: uppercase;
        color: #002a4e;
        text-align: center;
        margin-bottom: 15px;
    }
    &__desc {
        font-family: MrEavesXLModOT;
        font-size: 18px;
        line-height: 32px;
        text-transform: uppercase;
        color: #002a4e;
        text-align: center;
        margin-bottom: 20px;
    }
    .btn-send {
        background: #002a4e;
        border-radius: 10px;
        height: 48px;
        font-family: Roboto;
        font-size: 18px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        padding: 0 40px;
        color: #ffffff;
        width: 60%;
        margin: 0 auto;
        cursor: pointer;
    }

    .errorMessage {
        font-size: 16px;
        line-height: 32px;
        color: #ed4d32;
    }
    .successMessage {
        text-align: center;
        font-size: 16px;
        line-height: 32px;
        color: #28a745;
    }

    .form-enquire {
        .register-link {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 4px;
            margin-bottom: 16px;

            &__checkbox {
                font-family: MrEavesXLModOT;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 28px;
                color: #002a4e;

                a {
                    font-family: MrEavesXLModOT;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 28px;
                    color: #8a6e4b;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                &--box {
                    margin-right: 16px;
                }
            }
        }
    }
}
// input
.main-input {
    position: relative;
    margin-bottom: 20px;
    .content {
        position: relative;
    }
    label {
        font-family: Roboto;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #002a4e;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 20px;
        pointer-events: none;
        user-select: none;
        transition: 0.5s;
    }
    input {
        width: 100%;
        height: 48px;
        outline: none;
        border: 1px solid #002a4e;
        border-radius: 10px;
        font-family: Roboto;
        font-size: 14px;
        line-height: 20px;
        color: #002a4e;
        padding: 0 20px;
        &:focus + label {
            background: #fff;
            padding: 0 5px;
            top: 0;
            left: 15px;
        }
        &:not(:placeholder-shown) + label {
            background: #fff;
            padding: 0 5px;
            top: 0;
            left: 15px;
        }
    }
    span {
        color: red;
        font-family: Roboto;
        font-size: 12px;
    }
}
// textarea
.main-textarea {
    label {
        font-family: MrEavesXLModOT;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: #002a4e;
        display: block;
        margin-bottom: 7px;
    }
    textarea {
        outline: none;
        padding: 15px;
        font-family: Roboto;
        font-size: 12px;
        line-height: 16px;
        color: #002a4e;
        border: 1px solid #002a4e;
        height: 120px;
        border-radius: 10px;
        width: 100%;
        margin-bottom: 20px;
    }
    span {
        color: red;
        font-family: Roboto;
        font-size: 12px;
    }
}
// select
.main-select {
    margin-bottom: 20px;
    select {
        outline: none;
        border: none;
        border-bottom: 1px solid #002a4e;
        width: 100%;
        font-family: Roboto;
        font-size: 16px;
        line-height: 16px;
        text-transform: uppercase;
        color: #002a4e;
        padding: 6px 0;
        background: #ffffff;
    }
    span {
        color: red;
        font-family: Roboto;
        font-size: 12px;
    }
}
// area-code-phone
.area-code-phone {
    border: 1px solid #002a4e;
    border-radius: 10px;
    height: 48px;
    margin-right: 20px;
    width: 48px;
    font-family: Roboto;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #b6b8dd;
    text-align: center;
}
// row-model
.row-model {
    display: flex;
    .area-code-phone {
        flex-shrink: 0;
    }
    .main-input {
        flex: 1;
    }
}

.register {
    &__select {
        width: 100%;

        .ant-select-selector {
            height: 48px !important;
            outline: none;
            border: 1px solid #002a4e !important;
            border-radius: 10px !important;
            padding: 0px 20px !important;
        }

        .ant-select-selection-placeholder {
            display: flex;
            align-items: center;
            font-family: Roboto;
            font-size: 12px;
            line-height: 16px;
            color: #002a4e;
        }

        .ant-select-selection-item {
            display: flex;
            align-items: center;
        }
    }
}
