* {
    .lausanne-font {
        font-family: Lausanne !important;
    }
}

$mobile-height-360: 360px;

@mixin mobileH360 {
    @media screen and (max-height: $mobile-height-360),
        screen and (max-width: 549px) {
        @content;
    }
}

.filter-wrapper {
    color: #b3e4fa;
    overflow: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    // @include mobile {
    //     display: none;
    // }
    @include mobileH360 {
        display: none;
    }
    &::-webkit-scrollbar {
        width: 0px;
    }
    &__title {
        font-size: 42px;
        line-height: 44px;
        color: #b3e4fa;
        font-family: Cucina;
        margin-bottom: 5px;
        @include between-1025-to-1440 {
            font-size: 35px;
            line-height: 30px;
        }

        @include mobile {
            font-size: 32px;
            line-height: 44px;
        }
    }
    .main-title {
        cursor: pointer;
        font-size: 24px;
        font-family: Mesa;
        margin-bottom: 5px;
        font-family: Cucina;
        @include between-1025-to-1440 {
            font-size: 22px;
        }

        @include mobile {
            font-size: 24px;
            line-height: 32px;
        }
    }
    .title-search {
        font-size: 14px;
        padding-bottom: 5px;
        @include between-1025-to-1440 {
            font-size: 12px;
        }
    }
    .text-or {
        padding-bottom: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .pb-20 {
        margin-bottom: 0px;
    }
    .slider-range-custom {
        margin-left: 10px;
        margin-right: 10px;
    }
}
.border-bot {
    height: 2px;
    background-color: #b3e4fa;
    margin: 12px 0;
}
.filters-prop {
    @include between-1025-to-1440 {
        font-size: 12px;
    }
}
.filters-prop__item {
    display: flex;
    margin: 5px 0 0 0;
    .text-typo-14 {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    .total {
        padding: 0 16px;
        background-color: #b3e4fa;
        border-radius: 10px;
        color: #004f39;
        height: 25px;
        line-height: 25px;
        cursor: pointer;
    }
    .item {
        border-radius: 100%;
        border: 1px solid #b3e4fa;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 25px;
        cursor: pointer;
    }
    .none {
        padding: 0 16px;
        border-radius: 10px;
        color: #b3e4fa;
        border: 1px solid #b3e4fa;
        height: 25px;
        line-height: 25px;
        cursor: pointer;
    }
    .studio {
        padding: 0 16px;
        border-radius: 10px;
        border: 1px solid #b3e4fa;
        min-width: 67px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 25px;
        cursor: pointer;
    }
}
.ranger-field {
    display: flex;
    justify-content: space-between;
    margin: -16px 0 0 0;
}
.btn-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
    button {
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
    @include mobile {
        margin-top: 5px;
    }

    .btn-item {
        padding: 0 20px;
        font-size: 18px;
        border-radius: 10px;
        cursor: pointer;
    }
    .clear {
        background-color: transparent;
        border: 1px solid #b3e4fa;
        height: 34px;

        @include mobile {
            width: 100%;
        }
    }
    .search {
        background-color: #b3e4fa;
        border: 1px solid #b3e4fa;
        color: #004f39;
        height: 34px;

        @include mobile {
            width: 100%;
        }
    }
}

.filter-wrapper__mobile {
    color: #b3e4fa;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    display: none;
    overflow-y: hidden;

    &__sub-title {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;

        .main-title {
            opacity: 0.5;
            position: relative;
        }

        .main-title::before {
            position: absolute;
            content: "";
            top: 100%;
            left: 0;
            width: 100%;
            height: 2px;
            background: #b3e4fa;
            display: none;
        }

        .sub-title--active {
            opacity: 1;
            &::before {
                display: block;
            }
        }
    }

    &__btn-search {
        font-family: "Lausanne";
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        background: #b3e4fa;
        color: #004f39;
        text-align: center;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 10px;
        width: 100%;
        margin-top: 16px;
    }

    &-content {
        width: 100%;
        height: calc(100vh - 250px);
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        @include scroll-hidden;
    }

    &__filtered-by {
        &::-webkit-scrollbar {
            width: 0;
        }
        &__filter {
            display: flex;
            flex-direction: column;
            margin-bottom: 11px;
        }
    }

    @include mobileH360 {
        display: flex;
    }
}
