.WrapInput-admin {
    &__InputField {
        display: flex;
        flex-direction: column;
        border: 1px solid #e2e2ea;
        border-radius: 10px;
        height: 48px;
        display: flex;
        justify-content: center;
        padding: 0px 40px 0 20px;
        width: 100%;
        position: relative;
        &.disabled {
            background: #d7dbce;
        }
        .Input__main {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-label {
                font-family: "Proxima";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #979797;
                min-width: 78px;
            }

            input {
                font-family: Roboto;
                font-weight: 400;
                font-size: 18px;
                line-height: 20px;
                color: #002a4e;
                border: none;
                outline: none;
                background: none;
                flex: 1;
                &::placeholder {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 18px;
                    color: #002a4e;
                }
                @include tablet-max {
                    width: 90%;
                }
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                margin: 0;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                transition: background-color 5000s ease-in-out 0s;
            }
            img {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 18px;
                cursor: pointer;
            }
            .icon-eye {
                cursor: pointer;
            }
        }
    }
    .errorMessage {
        font-size: 16px;
        line-height: 32px;
        color: #ed4d32;
    }
}
