.location {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: grab;
    &__background {
        max-width: 100%;
        max-height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        overflow: auto;
    }

    &__image {
        max-width: 100%;
        max-height: 100%;
    }

    &__image-line {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        &.active {
            opacity: 1;
        }
    }

    &__viewbox {
        position: relative;
        height: 100%;
        overflow: hidden;
        width: 1920px;
        height: 1388px;
        margin: auto;
        svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: auto;

            .point-top-text {
                font-family: MrEavesXLModOT;
                font-size: 7px;
                text-transform: uppercase;
                color: #fff;
                text-align: center;
                > div {
                    font-family: MrEavesXLModOT;
                    line-height: 1.1;
                }
                &-child {
                    display: none;
                }
                div {
                    background-color: rgba(0, 87, 162, 0.6);
                    color: #fff;
                    border-radius: 4px;
                    text-align: center;
                    padding: 4px 2px 3px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .center-maker {
            polygon {
                opacity: 0.85;
            }
            line,
            foreignObject {
                animation: centerMaker 0.5s infinite alternate;
            }
        }

        .center-point {
            background-image: linear-gradient(
                to bottom,
                rgba(#002a4e, 0.1) 10%,
                rgba(#002a4e, 0.7) 30%,
                rgba(#002a4e, 0.9) 40%
            );
            padding: 35px 8px 12px;

            img {
                width: 100%;
                height: auto;
            }
        }

        .main-box {
            height: 100%;
            padding: 5px;
            background-color: #fff;
            border: 1px solid #8a6e4b;
            border-radius: 4px;
            color: #000;
            text-transform: uppercase;
            font-weight: 600;
            letter-spacing: 1px;
            text-align: center;
            line-height: 1 !important;

            &-bottom {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-top: 1px;
                line-height: 1 !important;

                img {
                    height: 12px;
                }

                span {
                    font-family: MrEavesXLModOT;
                    font-size: 8px;
                    color: #000;
                    padding-left: 5px;
                    letter-spacing: 0;
                    line-height: 1;
                }
            }
        }
        .line-main {
            display: none;
        }
    }
    &__point {
        position: absolute;
        color: #fff;
        cursor: default;

        .name {
            padding: 5px 12px;
            border-radius: 15px;
            background-color: #243670;
            display: inline-block;
            position: absolute;
            top: -100px;
            min-width: 120px;
            transform: translateX(-50%);
            white-space: nowrap;
            font-size: 12px;
            z-index: 5;
            text-align: center;
            img {
                width: 15px;
                margin-right: 5px;
            }
        }

        .l-arrow {
            height: 100px;
            width: 3px;
            background-color: #243670;
            position: absolute;
            bottom: 0;
            transform: translateX(-50%);
            display: inline-block;
            z-index: 2;
        }

        .l-icon {
            width: 220px;
            transform: translate(-55%, -75%);
        }
    }

    &__point--main {
        cursor: pointer;
    }

    .menu {
        position: absolute;
        width: 315px;
        left: -285px;
        z-index: 20;
        background: #243670;
        opacity: 0.9;
        border: 1.5px solid #b6b8dd;
        box-sizing: border-box;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        transition: all 0.3s linear;
        top: 0;
        bottom: 0;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        margin: auto;

        @include mobile-large() {
            width: 345px;
            left: -320px;
        }

        @media (max-height: 600px) {
            height: 90%;
        }

        &__icon {
            position: absolute;
            top: 45%;
            right: -5%;
            width: 41px;
            height: 41px;
            background: #b6b8dd;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            cursor: pointer;
            outline: none;
            transition: 0.5s;

            &:hover {
                background: #7b7eb1;
            }
        }

        &__icon--active {
            img {
                transform: rotate(180deg);
            }
        }

        &__list {
            padding: 10px 35px;
            margin-bottom: 0;
            overflow: auto;

            @include scroll-custom {
                &::-webkit-scrollbar {
                    width: 3px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                    margin-block-start: 30px;
                    margin-block-end: 30px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #888;
                }
            }
        }

        &__list-item {
            padding: 10px 0 10px 25px;
            font-family: "Roboto";

            a {
                font-family: "Roboto";
                font-weight: normal;
                font-size: 14px;
                line-height: 32px;
                color: #b6b8dd;
                text-decoration: none;
                span {
                    font-family: "Roboto";
                    padding-left: 24px;
                }
                @include mobile-large() {
                    font-size: 15px;
                }
            }

            &.active {
                a {
                    font-weight: 600;
                }
            }
        }

        &__list-item:not(:last-child) {
            border-bottom: 1.5px solid #b6b8dd;
        }
    }

    .menu--open {
        left: -1.5%;
    }
    .react-transform-wrapper {
        width: 100%;
        height: 100%;
        background-color: #002a4e;
    }
    &__zoom {
        position: relative;
        width: 100%;
        height: 100%;
    }
}
.point-top-text-compact > div {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: 100%;
    }
}
.point-top-text-compact:hover {
    ~ .point-top-text-child {
        display: block;
    }
    ~ .line-main {
        display: block;
    }
}
@-webkit-keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}
@keyframes centerMaker {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-12px);
    }
}
.point-plane {
    font-family: MrEavesXLModOT;
    font-size: 7px;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    &__main-box {
        height: 100%;
        height: 100%;
        display: flex;
    }
}

.national-park {
    &__triangle {
        background: transparent;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #f9dcab;
        order: 1;
        margin-top: 35px;
        margin-left: 1px;
        margin-right: unset;
    }
    &__content {
        background: #f9dcab;
        box-sizing: border-box;
        border-radius: 20px;
        width: calc(100% - 8px);
        order: 2;
    }
    img {
        padding: 3px;
        width: 100%;
        height: 75%;
        object-fit: cover;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    h3 {
        text-transform: uppercase;
        color: #8a6e4b;
        font-family: MrEavesXLModOT;
        font-style: normal;
        font-weight: 700;
        font-size: 6px;
        text-align: center;
        margin-bottom: 0;
    }
    p {
        text-transform: uppercase;
        color: #8a6e4b;
        font-family: MrEavesXLModOT;
        font-style: normal;
        font-weight: 700;
        font-size: 6px;
        text-align: center;
        margin-bottom: 0;
    }
}
.national-park--right {
    .national-park__triangle {
        border-left: 8px solid #f9dcab;
        border-right: unset;
        order: 2;
        margin-top: 35px;
        margin-right: 1px;
        margin-left: unset;
    }
    .national-park__content {
        margin-left: unset;
        order: 1;
    }
}
.loading-location {
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.9);
    color: #fff;
    width: 100%;
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - #{$height-menu-responsive});
    font-family: MrEavesXLModOT;
    font-style: normal;
    font-size: 20px;
    line-height: 18px;
    @include desktop-extra-large {
        height: calc(100vh - #{$height-menu});
    }
    @include screen-ipad-pro {
        height: calc(calc(var(--vh, 1vh) * 100) - #{$height-mobile-menu});
    }
}

@keyframes fade {
    0% {
        opacity: 0%;
    }
    25% {
        opacity: 25%;
    }
    50% {
        opacity: 50%;
    }
    75% {
        opacity: 75%;
    }
    100% {
        opacity: 100%;
    }
}
