.modal-main-admin {
    .ant-modal-content {
        background: #ffffff;
        box-shadow: 0px 5px 15px rgba(68, 68, 79, 0.1);
        border-radius: 24px;
    }
}
.modal-content-confirm {
    padding: 24px 60px;
    text-align: center;
    img {
        margin-bottom: 15px;
    }
    p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 120%;
        color: #000000;
    }
    button {
        cursor: pointer;
        height: 48px;
        background: #fceed5;
        min-width: 150px;
        padding: 0 24px;
        border: 2px solid #cea46f;
        border-radius: 200px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        color: #002a4e;
        &.cancel {
            background: #ffffff;
            border-color: #d7dbce;
        }
        &:last-child {
            margin-left: 10px;
        }
    }
}
.modal-content-compare {
    padding: 24px 60px;
    h2 {
        font-family: PlayfairDisplay;
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 132%;
        text-align: center;
        color: #002a4e;
    }
    img {
        width: 100%;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #d7dbce;
        padding: 8px 0;
    }
    span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #002a4e;
    }
    .value {
        font-weight: 700;
        color: #002a4e;
    }
}
.modal-content-share {
    padding: 15px 0px;
    h2 {
        font-family: RozhaOne;
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 132%;
        text-align: center;
        color: #9c361a;
    }
    &__thumbnail {
        height: 200px;
        border-radius: 10px;
        overflow: hidden;
        overflow-y: auto;
        margin-bottom: 24px;
        background: #fceed5;
        padding: 17px 23px;
        display: flex;
        flex-direction: column;
        gap: 12px;

        &--single {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &--item {
            border-radius: 10px;
            background-color: #f9dcab;
            padding: 14.5px 16px;
            display: flex;
            justify-content: space-between;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: 15px;
                color: #5e0f16;
                margin: 0px;
            }

            img {
                cursor: pointer;
            }
        }
    }
    button {
        cursor: pointer;
        width: 100%;
        height: 48px;
        background: #fceed5;
        padding: 0 24px;
        border: 2px solid #cea46f;
        border-radius: 200px;
        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        color: #002a4e;
        &.share {
            background: #ffffff;
            border-color: #d7dbce;
        }
    }
    &__row {
        margin-bottom: 16px;
    }
}
