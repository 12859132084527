.modal-notice-zoom {
    .ant-modal-content {
        background-color: transparent;
        border-radius: 20px;
        overflow: hidden;
    }
    &__mask {
        position: absolute;
        inset: 0;
        background: #000000;
        opacity: 0.5;
    }
    .ant-modal-close {
        top: -10px;
        right: -10px;
        img {
            width: 22px;
            height: 22px;
        }
    }
    .ant-modal-body {
        padding: 18px 0;
    }
}
.block-notice-zoom {
    display: flex;
    text-align: center;
    position: relative;
    &__left {
        width: 50%;
        border-right: 1px solid #fff;
        padding: 20px;
    }
    &__right {
        width: 50%;
        padding: 20px;
    }
    span {
        margin-left: 25px;
        display: inline-block;
        font-family: MrEavesXLModOT;
        font-style: normal;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #ffffff;
    }
}
