.apartments {
    $tablet-width: 1366px;
    $desktop-width: 1440px;

    $mobile-height-360: 360px;

    overflow: hidden;
    @mixin tablet {
        @media (max-width: #{$tablet-width}) {
            @content;
        }
    }

    @mixin desktop {
        @media (min-width: #{$tablet-width + 1px}) and (max-width: #{$desktop-width}) {
            @content;
        }
    }

    @mixin mobileH360 {
        @media (max-height: $mobile-height-360) {
            @content;
        }
    }

    .span-link {
        cursor: pointer;
    }

    width: 100%;
    height: 100%;
    position: relative;

    &__background {
        width: 100%;
        height: 100%;
        position: relative;
        background-color: #243670;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .bg-image {
            width: 100%;
            height: 100%;
            background-color: #b3e4fa;
            object-fit: contain;
            position: absolute;
            z-index: 0;
        }

        .view-box {
            position: relative;
            max-height: 100%;
            width: fit-content;
            margin: auto;

            @include tablet-max {
                width: 100%;
                overflow-x: auto;
            }

            @include tablet-res-height {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &--image {
            max-width: 100%;
            min-width: 100%;
            max-height: 100%;
            min-height: 100%;
            margin: auto;

            @include tablet-max {
                max-width: none;
            }
        }

        .overlays-svg {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 5;
            width: 100%;
            height: 100%;

            svg {
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;

                polygon {
                    fill: #b3e4fa;
                    cursor: pointer;
                    stroke-width: 1px;
                    stroke: #163d5e;
                    opacity: 0.75;
                    transition: opacity 0.25s;
                    -moz-transition: opacity 0.25s;
                    -webkit-transition: opacity 0.25s;
                }

                text {
                    fill: #163d5e;
                    font-size: 24px;
                    line-height: 32px;
                    font-family: Lausanne;
                }
                .animation-text-floor {
                    font-family: Cucina;
                    font-size: 15px;
                    fill: #163d5e;
                }

                .name-building {
                    font-family: Roboto;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    letter-spacing: 0em;
                    text-align: center;
                }

                .animation-text-floor,
                .animation-line-floor {
                    transform: translateY(-50px);
                }

                .isMbC {
                    animation: scaling 1.5s linear infinite;
                    animation-delay: 0.8s;
                    fill: #163d5e;
                    transform-origin: 50% 50%;
                    transform-box: fill-box;
                }

                .isMbT {
                    fill: #163d5e;
                    opacity: 1;
                    transform-origin: 50% 50%;
                    transform-box: fill-box;
                    stroke: #ffffff;
                    stroke-miterlimit: 1;
                }

                g.a-item-g {
                    opacity: 0;
                    transition: opacity ease-in-out 0.2s;

                    line,
                    text {
                        display: none;
                    }
                    &--show {
                        opacity: 1;
                        transition: opacity ease-in-out 0.2s;

                        line,
                        text {
                            display: block;
                        }
                    }
                    &:hover {
                        opacity: 1;
                        transition: opacity ease-in-out 0.2s;

                        line,
                        text {
                            display: block;
                        }
                    }
                }
            }

            @keyframes scaling {
                0% {
                    transform: scale(1);
                }

                50% {
                    transform: scale(1.4);
                }

                100% {
                    transform: scale(1);
                }
            }
        }
    }

    &__header {
        position: absolute;
        top: 6%;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        padding: 0 40px;

        @include tablet-max {
            display: none;
        }

        h2 {
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
            color: #ffffff;
            text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            margin-left: 50px;

            @include desktop-extra-middle {
                font-size: 20px;
            }
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 12px;
            border-radius: 50%;
            background: #ffffff;
            border: 1px solid #f04e26;

            span {
                font-weight: bold;
                font-size: 16px;
                line-height: 24px;
                color: #f04e26;
            }
        }
    }

    &__filters {
        position: absolute;
        top: 50%;
        left: -557px;
        transform: translateY(-50%);
        width: 630px;
        height: 90%;
        background: #002a4e;
        border-radius: 0px 30px 30px 0px;
        transition: all 0.3s linear;
        padding: 30px;
        border-left: 0;
        z-index: 9;

        @include between-1025-to-1440 {
            height: 96%;
            padding: 15px 30px;
        }
        @include screen-2560 {
            height: 50%;
        }

        @include ipad-pro {
            height: 55%;
        }

        @include tablet-mini {
            height: 65%;
        }

        @include tablet-res-height {
            height: 100%;
        }

        @include mobile {
            height: 90%;
            left: -585px;
        }

        &-icon {
            position: absolute;
            top: 47%;
            right: -3%;
            border: none;
            height: 41px;
            width: 41px;
            padding: 12px;
            background: #f9dcab;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.5s;
        }

        &-closeBtn {
            width: 41px;
            height: 41px;
            border: 1px solid #f9dcab;
            background: #f9dcab;
            text-align: center;
            border-radius: 50%;
            position: absolute;
            right: 24px;
            top: 24px;
            cursor: pointer;
            display: none;

            svg {
                position: relative;
                top: 3px;
            }
        }

        &-icon--active {
            transform: rotate(180deg);
        }

        &-title {
            font-family: MrEavesXLModOT;
            font-weight: normal;
            font-size: 28px;
            line-height: 32px;
            color: #fff;
            display: none;
            transform: translateX(-30px);
            @include mobile-large {
                visibility: hidden;
            }
        }

        &-title--active {
            position: absolute;
            top: 47%;
            right: -10.5%;
            transform: rotate(-90deg);
            display: block;
        }
    }

    &__filters--open {
        left: 0;

        @include mobile {
            width: 100%;
            height: 100%;
            border-radius: 0px;
            padding: 70px 24px 10px 24px;
            z-index: 99;

            .apartments__filters-icon {
                display: none;
            }

            .apartments__filters-closeBtn {
                display: block;
            }
        }

        @include mobileH360 {
            width: 100%;
            height: 100%;
            border-radius: 0px;
            padding: 70px 24px 10px 24px;
            z-index: 99;

            .apartments__filters-icon {
                display: none;
            }

            .apartments__filters-closeBtn {
                display: block;
            }
        }
    }
    &__filters--open-filters {
        width: 550px;

        @include mobile {
            width: 100%;
            height: 100%;
            border-radius: 0px;
            padding: 70px 24px 10px 24px;
            z-index: 99;

            .apartments__filters-icon {
                display: none;
            }

            .apartments__filters-closeBtn {
                display: block;
            }
        }

        @include mobileH360 {
            width: 100%;
            height: 100%;
            border-radius: 0px;
            padding: 70px 24px 10px 24px;
            z-index: 99;

            .apartments__filters-icon {
                display: none;
            }

            .apartments__filters-closeBtn {
                display: block;
            }
        }
    }
    &__virtual {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 1px solid #f04e26;
        position: absolute;
        top: 37px;
        right: 105px;
        background-color: #ffffff;
        cursor: pointer;
        z-index: 10;
        display: flex;
        justify-content: center;
        img {
            width: 25px;
        }

        @include tablet-max {
            display: none;
        }
    }
    &__modal {
        height: 100% !important;
        margin: 0 auto;
        .ant-modal-content {
            width: 100%;
            height: 85%;
            margin-top: 10px;
            border-radius: 5px;
        }
        .ant-modal-body {
            height: 100%;
            padding: 0;
        }

        &--pdf {
            .ant-modal-body {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .img-pdf {
                max-height: 100%;
                max-width: 100%;
            }
        }
    }
    .ant-modal-mask {
        background-color: inherit !important;
    }

    &__popup {
        width: 100%;
        height: 100%;
        iframe {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            border: 1px solid #002a4e;
        }
    }
}

.detail-level {
    background: linear-gradient(180deg, #fbe3bc 0%, #ffffff 100%);
    position: relative;
    overflow: hidden;
    padding-left: 90px;
    padding-right: 90px;
    @include screen-ipad-pro {
        overflow: auto;
        padding-left: 15px;
        padding-right: 15px;
    }
    &__wrapper {
        margin-top: 0;
        display: flex;
        flex-direction: column;
    }

    &__content {
        margin-top: 0;
        padding: 30px 0 60px;
        height: calc(100% - 105px) !important;
    }

    .content-right {
        border: 1.5px solid #f9dcab;
        padding: 30px 50px;
        display: flex;
        align-items: center;
        position: static;
        @include screen-ipad-pro {
            padding: 0;
            height: 400px;
            overflow-x: auto;
            border-radius: 10px;
        }
        .block-show-pdf {
            cursor: pointer;
            position: absolute;
            top: 20px;
            right: 40px;
            font-family: Lausanne;
            font-style: normal;
            font-weight: normal;
            font-size: 8px;
            line-height: 10px;
            color: #163d5e;
            z-index: 6;
            img {
                margin-left: 15px;
            }
        }
    }

    .content-left {
        text-align: left;
        border: 1.5px solid #f9dcab;
        padding: 20px 45px;
        ::-webkit-scrollbar {
            width: 20px;
        }
        ::-webkit-scrollbar-track {
            background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #d6dee1;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #d6dee1;
            border-radius: 20px;
        }

        ::-webkit-scrollbar-thumb {
            background-color: #d6dee1;
            border-radius: 20px;
            border: 6px solid transparent;
            background-clip: content-box;
        }

        ::-webkit-scrollbar-thumb:hover {
            background-color: #a8bbbf;
        }

        &__keys {
            padding-top: 18px;
            padding-left: 6px;
            flex-grow: 1;
            overflow: auto;
        }

        hr {
            width: 100%;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        @media (max-height: 815px) {
            h4 {
                margin-bottom: 5px;
                font-size: 35px;
                line-height: 35px;
            }

            &__keys {
                padding-top: 5px;
            }

            hr {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }

        @media (max-height: 720px) {
            h4 {
                font-size: 25px;
                line-height: 25px;
            }
        }
        @include screen-ipad-pro {
            padding: 15px;
            border-radius: 10px;
        }
    }

    &__status {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 16px;
        .dots {
            height: 28px;
            width: 28px;
            border-radius: 100%;
            display: inline-block;
            border: 5px solid transparent;
            background-color: #fff;
        }

        .text {
            display: inline-block;
            margin-left: 24px;
            font-size: 14px;
            color: #252225;
        }

        @media (max-height: 815px) {
            margin-bottom: 14px;
            .dots {
                height: 24px;
                width: 24px;
                border-width: 3px;
            }
        }

        @media (max-height: 720px) {
            margin-bottom: 0;
            .dots {
                height: 15px;
                width: 15px;
            }
        }

        @media screen and (max-height: 718px) {
            margin-bottom: 10px;
        }

        @media screen and (max-height: 668px) {
            margin-bottom: 4px;
        }
    }

    &__key {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;

        .dots {
            height: 28px;
            width: 28px;
            border-radius: 100%;
            display: inline-block;
        }

        .text {
            display: inline-block;
            margin-left: 24px;
            font-size: 14px;
            color: #252225;
        }

        @media (max-height: 815px) {
            margin-bottom: 10px;
            .dots {
                height: 24px;
                width: 24px;
            }
        }

        @media (max-height: 720px) {
            margin-bottom: 0;
            .dots {
                height: 15px;
                width: 15px;
            }
        }

        @media screen and (max-height: 718px) {
            margin-bottom: 9px;
        }

        @media screen and (max-height: 668px) {
            margin-bottom: 6px;
        }
    }

    &__view {
        max-width: 100%;
        height: 100%;
        text-align: center;
        margin: auto;
        width: fit-content;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @include screen-ipad-pro {
            justify-content: unset;
        }
        img {
            max-height: 100%;
            max-width: 100%;
            @include screen-ipad-pro {
                max-width: unset;
            }
        }
    }

    &__paginate {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: 10px;
        @include screen-ipad-pro {
            justify-content: unset;
            margin-bottom: 10px;
            flex-direction: column;
            align-items: unset;
        }
        @media only screen and (max-width: 700px) {
            flex-direction: row;
            justify-content: center;
        }
        &--label {
            color: #163d5e;
            font-size: 18px;
            line-height: 32px;
            padding-right: 11px;
        }

        &--pages {
            display: flex;
            flex-direction: row;
        }

        &--link {
            height: 23px;
            width: 23px;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: center;
            border-radius: 100%;
            border: 1px solid #163d5e;
            color: #163d5e;
            margin-right: 15px;

            &.active {
                color: #fff;
                background-color: #163d5e;
            }
        }
        .pagination-select {
            .ant-select-selector {
                background-color: #163d5e;
                color: #fff;
                border-radius: 12px;
            }
            .ant-select-arrow {
                color: #fff;
            }
        }
    }

    .overlays-svg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 5;
        width: 100%;
        height: 100%;

        svg {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;

            polygon {
                cursor: pointer;
                stroke-width: 2px;
                fill-opacity: 0;
                transition: fill-opacity 0.25s;
                -moz-transition: fill-opacity 0.25s;
                -webkit-transition: fill-opacity 0.25s;
            }

            path {
                cursor: pointer;
                stroke-width: 2px;
                fill-opacity: 0;
                transition: fill-opacity 0.25s;
                -moz-transition: fill-opacity 0.25s;
                -webkit-transition: fill-opacity 0.25s;
            }

            g {
                &:hover {
                    polygon {
                        fill-opacity: 0.75;
                        transition: fill-opacity 0.25s;
                        -moz-transition: fill-opacity 0.25s;
                        -webkit-transition: fill-opacity 0.25s;
                    }
                    path {
                        fill-opacity: 0.75;
                        transition: fill-opacity 0.25s;
                        -moz-transition: fill-opacity 0.25s;
                        -webkit-transition: fill-opacity 0.25s;
                    }
                }
            }
        }
    }

    .col-sm {
        height: 100%;
        @include screen-ipad-pro {
            height: unset;
        }
    }

    .col-sm-key {
        @include screen-ipad-pro {
            padding-bottom: 20px;
        }
    }

    .detail-product__back {
        padding-bottom: 30px;
    }

    .content-header {
        position: absolute;
        right: 120px;
        top: 35px;
        @include screen-ipad-pro {
            position: relative;
            right: unset;
            top: unset;
        }
        &__title {
            font-size: 42px;
            line-height: 44px;
            margin-bottom: 0;
            font-weight: 400;
            color: #163d5e;
        }

        &__subtitle {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 0;
            font-weight: 400;
            color: #163d5e;
        }
    }

    @media (max-height: 815px) {
        .content-header {
            &__title {
                font-size: 35px;
                line-height: 37px;
            }
        }
    }
}

.antd-checkbox-custom {
    .ant-checkbox-inner {
        border: 1px solid #1c1c1c;
        width: 22px;
        height: 22px;
        background-color: #fff;
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner::after {
            border-color: #1c1c1c;
            left: 30.5%;
        }

        &::after {
            border-color: #1c1c1c;
        }
    }

    &:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
        border-color: #1c1c1c;
    }
}
