.lifestyle_detail {
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #cea46f 52.31%, #002a4e 100%);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 40px;

    @include desktop-supper-middle() {
        padding-top: 20px;
    }

    @include desktop-extra-middle() {
        padding-top: 20px;
    }

    @include desktop-extra-s() {
        padding-top: 20px;
    }

    @include tablet-max() {
        padding-top: 0px;
        align-items: center;
    }

    &__btn {
        display: flex;
        gap: 21px;
        position: absolute;
        bottom: -12px;
        right: 0px;

        @include mobile-large() {
            position: relative;
            margin: auto;
            bottom: auto;
            right: auto;
        }

        @include mobile-large-landscape() {
            display: none;
        }

        SVG {
            cursor: pointer;
        }
    }

    &__wrapper {
        width: 45vw;
        position: relative;

        @include desktop-extra-large() {
            width: 60vw;
        }

        @include width-1440-height-1024() {
            width: 70vw;
        }

        @include desktop-supper-middle() {
            width: 55vw;
        }

        @include desktop-extra-middle() {
            width: 40vw;
        }

        @include desktop-extra-s() {
            width: 70vw;
        }

        @include tablet-large-max() {
            width: 90vw;
        }

        @include mobile-large() {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 27px;
        }

        @include desktop-supper() {
            width: 55vw;
        }

        .ant-tabs {
            .ant-tabs-nav {
                margin-bottom: 27px;

                .ant-tabs-nav-list {
                    gap: 100px;

                    @include desktop-supper-middle() {
                        gap: 50px;
                    }

                    @include tablet-max() {
                        gap: 20px;
                    }

                    @include mobile-large() {
                        gap: 10px;
                    }

                    .ant-tabs-tab {
                        color: #fff;
                        font-family: MrEavesXLModOT;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 18px;
                    }
                }

                .ant-tabs-ink-bar {
                    background: #f9dcab;
                    height: 5px !important;
                }

                &::before {
                    border-bottom: 1px solid #203d54;
                }
            }

            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #fff;
            }
        }
    }

    &__carousel {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__thumb {
        width: 100%;
        height: 100%;
        position: relative;

        &--absolute {
            position: absolute;
            width: 100%;
            height: 38%;
            bottom: 0px;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                #cea46f 100%
            );
        }
    }

    &__text {
        width: 100%;
        float: left;
        font-family: MrEavesXLModOT;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
        color: #fff;
        margin-top: 27px;
        display: flex;
        align-items: flex-start;

        @include mobile-large-landscape() {
            display: none;
        }

        @include mobile-large() {
            align-items: center;
            line-height: 16px;
        }

        &--title {
            font-size: 32px;

            @include desktop-extra-middle() {
                font-size: 26px;
            }

            @include mobile-large() {
                font-size: 22px;
            }
        }

        &--dot {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: #fff;
        }
    }

    .justify-center {
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;

        @include desktop-super-middle-max {
            justify-content: space-evenly;
        }

        @include mobile-large {
            gap: 8px;

            span {
                display: none;
            }
        }
    }
}
