.button-admin {
    line-height: 20px;
    outline: none;
    padding: 0 20px;
    height: 48px;
    font-family: Roboto;
    font-weight: 700;
    font-size: 18px;
    color: #002a4e;
    cursor: pointer;
    background: #fceed5;
    border: 2px solid #cea46f;
    border-radius: 200px;
    &__btn--delete {
        background: #252235;
        border-radius: 10px;
        @include mobile-large {
            margin-right: 10px;
        }
    }
    &__btn--disabled {
        cursor: no-drop;
        opacity: 0.65;

        &:hover {
            box-shadow: none;
        }
    }
}
