.team {
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #cea46f 52.31%, #002a4e 100%);

    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 100px 100px;
        position: relative;

        @include mobile-large {
            padding: 50px 36px;
        }

        @include mobile-max-height {
            padding: 40px 36px;
        }

        &--btn {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 0px 20px;

            svg {
                cursor: pointer;
            }

            @include mobile-large {
                bottom: -20px;
                top: unset;
                justify-content: center;
                gap: 16px;
                padding: 0px;
            }
        }

        .ant-carousel {
            width: 100%;

            .slick-track {
                display: flex;
                align-items: center;
            }
        }
    }

    &__carousel {
        width: 100%;
        height: 100%;
        display: flex !important;

        @include tablet-max {
            flex-direction: column;
            gap: 36px;
        }

        &--info {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-basis: 40%;

            @include tablet-max {
                flex-basis: 50%;
                gap: 24px;
            }

            @include mobile-max-height {
                gap: 16px;
            }
        }

        &--image {
            width: 100%;
            flex-basis: 60%;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }

            @include tablet-max {
                flex-basis: 50%;
            }
        }
    }

    &__title {
        width: 100%;
        display: flex;

        font-family: MrEavesXLModOT;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 49px;
        color: #31435f;

        @include tablet-max {
            font-size: 64px;
            line-height: 72px;
        }

        @include tablet-large-max {
            font-size: 48px;
            line-height: 56px;
        }

        @include mobile-large {
            font-size: 26px;
            line-height: 30px;
        }
    }

    &__content {
        font-family: MrEavesXLModOT;
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        padding-right: 48px;

        @include desktop-extra-large-max() {
            font-size: 22px;
            line-height: 26px;
        }

        @include tablet-max {
            font-size: 24px;
            line-height: 36px;
            padding: 0px;
        }

        @include tablet-large-max {
            font-size: 20px;
            line-height: 28px;
        }

        @include mobile-large {
            font-size: 14px;
            line-height: 16px;
        }
    }

    &__link {
        font-family: MrEavesXLModOT;
        font-style: normal;
        font-size: 25px;

        @include desktop-extra-large-max() {
            font-size: 22px;
        }

        @include tablet-max {
            font-size: 24px;
            line-height: 36px;
        }

        @include tablet-large-max {
            font-size: 20px;
            line-height: 28px;
        }

        @include mobile-large {
            font-size: 14px;
            line-height: 16px;
        }
    }
}
