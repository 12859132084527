@font-face {
    font-family: Cucina;
    src: url(../../fonts/Cucina/bretagne_cucina-neretta.otf);
}

@font-face {
    font-family: Lausanne;
    src: url(../../fonts/Lausanne/Fonts/Desktop/TWKLausanne-300.otf);
}

@font-face {
    font-family: Proxima;
    src: url(../../fonts/Proxima/Fonts/Desktop/Proxima-Nova-Font.otf);
}

@font-face {
    font-family: MrEavesXLModOT;
    src: url(../../fonts/MrEavesXLModOT.ttf);
}

@font-face {
    font-family: Roboto;
    src: url(../../fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: PlayfairDisplay;
    src: url(../../fonts/PlayfairDisplay/PlayfairDisplay-Regular.ttf);
}
